export default {
    name: "TopTradedShares",
    created () {
        this.$nextTick(function () {
            // 获取表格数据
            this.GetMarketData()
            $(window).resize(function () {
                console.log(1);
                this.tableBoxW = $('#TopTradedShares').find('.tableBox ').width()
                this.tableBoxdiv1W = $('#TopTradedShares').find('.tableBox ').find('div').eq(0).width()
                this.tableBoxdiv2W = $('#TopTradedShares').find('.tableBox ').find('div').eq(1).width()
                if (this.tableBoxW > this.tableBoxdiv1W + this.tableBoxdiv2W) {
                    $('#TopTradedShares').find('.shift').hide()
                } else {
                    $('#TopTradedShares').find('.shift').show()
                }
            })
        });
    },
    mounted () {
        this.$nextTick(function () {
            // this.$$.navbarAnimation()
        })
    },
    data () {
        return {
            // 列表头部内容
            tableHeadContent: [
                this.$t('TTSs.UbS'),
                `${this.$t('TTSs.TV')} <br/>('000)`,
                this.$t('TTSs.LbP'),
                this.$t('WMs.LC'),
                this.$t('WSe.WN'),
                this.$t('WSe.DtLTD'),
                this.$t('hp.BP'),
                this.$t('WSe.BC'),
                this.$t('WSe.BV') + "<br/> ('000)",
                this.$t('int.Se'),
                this.$t('int.EG')
            ],
            // 列表内容
            tableContentData: [],
            tableBoxW: 0,
            tableBoxdiv1W: 0,
            tableBoxdiv2W: 0
        };
    },
    computed: {

    },
    watch: {

    },
    methods: {
        // 获取表格数据
        GetMarketData: function () {
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: this.$$.mibb + "GetMarketData",
                data: { token: "webkey", type: 'toptradedstock' },
                success: (data) => {
                    if (data.status == 'SUCCESS') {
                        console.log(data);
                        this.tableContentData = data.topList
                        this.tableBoxW = $('#TopTradedShares').find('.tableBox ').width()
                        this.tableBoxdiv1W = $('#TopTradedShares').find('.tableBox ').find('div').eq(0).width()
                        this.tableBoxdiv2W = $('#TopTradedShares').find('.tableBox ').find('div').eq(1).width()
                        if (this.tableBoxW > this.tableBoxdiv1W + this.tableBoxdiv2W) {
                            $('#TopTradedShares').find('.shift').hide()
                        } else {
                            $('#TopTradedShares').find('.shift').show()
                        }

                    }
                },
                error: function (XMLHttpRequest) { }
            });
        },
        clickShiftRight: function () {
            let scrollRight = $('#TopTradedShares').find('.tableBox').scrollLeft()
            $('#TopTradedShares').find('.tableBox').scrollLeft(scrollRight + 10)
        },
        clickShiftLeft: function () {
            let scrollRight = $('#TopTradedShares').find('.tableBox').scrollLeft()
            $('#TopTradedShares').find('.tableBox').scrollLeft(scrollRight - 10)
        },
    }
};